<template>
  <div class="pa-0 ma-0 d-flex flex-column">
    <jumbotron src="https://storage.googleapis.com/moonhope-174701.appspot.com/photo_2020-05-31_22-23-36.jpg" :aspect-ratio="3">
      <v-container fluid class="fill-height white--text ma-0 pa-1">
        <v-row class="fill-height ma-0" align="end">
          <h1
            :class="{
              'display-3': isOnPC,
              'display-1': !isOnPC
            }"
            style="text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.75)"
          >
            AmborJo Marketplace
          </h1>
        </v-row>
      </v-container>
    </jumbotron>
    <v-container class="mt-2">
      <v-row><h2 class="headline">Vendors</h2></v-row>
      <v-row><vendor-list /></v-row>
    </v-container>
  </div>
</template>

<script>
import Jumbotron from '../components/Jumbotron'
import VendorList from '../components/VendorList/VendorList'

export default {
  name: 'MarketplaceView',

  components: {
    'vendor-list': VendorList,
    jumbotron: Jumbotron
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },

  beforeMount () {
    document.title = 'Market - AmborJo'
  }
}
</script>
