<template>
  <v-card :to="`/marketplace/${vendor.id}/${vendor.url ? vendor.url : ''}`">
    <v-img aspect-ratio="1" :src="vendor.primaryPhoto" class="fill-height align-end" gradient="to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75)">
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular indeterminate></v-progress-circular>
        </v-row>
      </template>
      <v-card-title class="white--text no-underline text-no-wrap" v-text="vendor.name" />
    </v-img>
  </v-card>
</template>

<script>
export default {
  name: 'VendorCard',
  props: {
    vendor: {
      required: true,
      type: Object
    }
  }
}
</script>
