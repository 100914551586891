<template>
  <v-container class="fill-height ma-0 pa-0" fluid>
    <v-row class="ma-0 pa-0" v-if="vendors.length">
      <v-col xs="12" sm="6" md="4" lg="3" xl="2" v-for="vendor in vendors" :key="JSON.stringify(vendor)">
        <vendor-card :vendor="vendor" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from '../../plugins/firebase'
import VendorCard from './VendorCard'

export default {
  name: 'VendorList',
  components: {
    'vendor-card': VendorCard
  },

  data () {
    return ({
      loading: false,
      vendors: []
    })
  },

  methods: {
    getVendorList () {
      var results = []
      return new Promise((resolve, reject) => {
        firebase.firestore().collection('/vendors').get().then(querySnapshot => {
          querySnapshot.forEach(doc => {
            results.push(Object.assign({ id: doc.id }, doc.data()))
          })
          resolve(results)
        }).catch(e => { reject(e) })
      })
    }
  },

  async mounted () {
    this.$data.loading = true
    try {
      this.$data.vendors = await this.getVendorList()
    } catch (error) {
      // console.error(error)
      this.$store.commit('toast/setError', 'Error getting vendors.')
    }
    this.$data.loading = false
  }
}
</script>
